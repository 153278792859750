exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-data-downloads-index-tsx": () => import("./../../../src/pages/data-downloads/index.tsx" /* webpackChunkName: "component---src-pages-data-downloads-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-local-health-panorama-index-tsx": () => import("./../../../src/pages/local-health-panorama/index.tsx" /* webpackChunkName: "component---src-pages-local-health-panorama-index-tsx" */),
  "component---src-pages-methods-documentation-index-tsx": () => import("./../../../src/pages/methods-documentation/index.tsx" /* webpackChunkName: "component---src-pages-methods-documentation-index-tsx" */),
  "component---src-pages-obesidade-e-as-dcnt-index-tsx": () => import("./../../../src/pages/obesidade-e-as-dcnt/index.tsx" /* webpackChunkName: "component---src-pages-obesidade-e-as-dcnt-index-tsx" */),
  "component---src-pages-projects-gallery-index-tsx": () => import("./../../../src/pages/projects-gallery/index.tsx" /* webpackChunkName: "component---src-pages-projects-gallery-index-tsx" */),
  "component---src-pages-visualizations-index-tsx": () => import("./../../../src/pages/visualizations/index.tsx" /* webpackChunkName: "component---src-pages-visualizations-index-tsx" */)
}

